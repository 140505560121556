import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'gatsby';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import PostTile from '@components/post-tile/post-tile';

import * as s from './blog-posts-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

const BLOG_POSTS_QUERY = gql`
    query PromoCarQuery {
        posts(first: 4, where: {orderby: {field: DATE, order: DESC}}) {
			nodes {
                categories(first: 1) {
                    nodes {
                    name
                    }
                }
                post {
                    smallImage {
                    sourceUrl
                    }
                    excerpt
                }
                date
                slug
                title
			}
		}
    }
`;

const BlogPostsSection = ({ classes }) => {

    const { loading, error, data } = useQuery(BLOG_POSTS_QUERY);

    return (
        <section className={`${s.blog_posts_section} ${s_wrapper.apply} ${s_wrapper.apply_low_top_height}`}>

            <h2>Nowości na blogu</h2>

            <p className={`${s_text.subtitle} ${s.subtitle}`}>
                Chcesz dowiedzieć się więcej o dostępnych formach finansowania, a także ich zaletach i wadach?<br/>
                Odwiedź nasz blog i poznaj porady ekspertów!
            </p>

            <div className={s.items_wrapper}>

                <HandleLoading loading={loading} error={error}>
                    {data?.posts.nodes.map((el, id) => (
                        <PostTile
                            classes={s.item}
                            key={id}
                            title={el.title}
                            mainImgUrl={el.post.smallImage.sourceUrl}
                            date={el.date.split('T')[0]}
                            slug={el.slug}
                            excerpt={el.post.excerpt}
                            category={el.categories.nodes[0].name}
                        />
                    ))}
                </HandleLoading>

            </div>

            <Link to='/blog' className={`${s_btns.btn_full} ${s_btns.btn_arrow} ${s.more_btn}`}>Więcej</Link>

        </section>
    )
};

export default BlogPostsSection;
