import React from 'react';
import { Link } from 'gatsby';

import * as s from './post-tile.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';


const PostTile = ({classes, mainImgUrl, slug, title, date, excerpt, category}) => {


    return (
        <div className={`${s.post_tile} ${classes}`}>
            <Link to={`/blog/${slug}`} className={s.link}>
                <img className={s.main_img} src={mainImgUrl} alt="Zdjęcie wpisu" />

                <div className={s.info_wrapper}>
                    <div className={s.category}>
                        {category}
                    </div>
                    <div className={s.date}>
                        {date}
                    </div>
                </div>

                <div className={s.title_wrapper}>
                    <h4>{title}</h4>
                </div>

                <p>
                    {excerpt}
                </p>

                <button className={s_btns.btn_outline_white}>Zobacz wpis</button>

            </Link>
        </div>
    )
};

export default PostTile;
