import React from 'react';
import { useQuery, gql } from '@apollo/client';

import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import HandleLoading from '@hoc/handle-loading/handle-loading';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';
import BlogPostsSection from '@components/blog-posts-section/blog-posts-section';

import * as s from './single-post.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';
import './wp-styles.scss';

const BLOG_POST_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        postBy(slug: $slug) {
            categories(first: 1) {
                nodes {
                    name
                }
            }
            content
            date
            title
            post {
                bigImage{
                    sourceUrl
                }
                metaTitle
                metaDescription
            }
        }
    }
`;

const SinglePost = (props) => {

    const { loading, error, data } = useQuery(BLOG_POST_QUERY, {
        variables: {slug: props['single-post']}
    });


    return (
        <MainLayout>

			<BreadCrumbs />

            <section className={`${s.post_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>
                <HandleLoading loading={loading} error={error}>

                    <Seo
                        title={data?.postBy.post.metaTitle}
                        description={data?.postBy.post.metaDescription}
                    />

                    <h1>{data?.postBy.title}</h1>

                    <img className={s.main_img} src={data?.postBy.post.bigImage.sourceUrl} alt="Zdjęcie artykułu" />

                    <div className={s.info_wrapper}>
                        <div className={s.category}>
                            {data?.postBy.categories.nodes[0].name}
                        </div>
                        <div className={s.date}>
                            {data?.postBy.date.split('T')[0]}
                        </div>
                    </div>

                    <article dangerouslySetInnerHTML={{ __html: data?.postBy.content }}>
                    </article>

                </HandleLoading>
            </section>

            <BlogPostsSection />

            <ContactSection />

            <MapSection />

        </MainLayout>
    )
};

export default SinglePost;
